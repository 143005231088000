import React, { Component } from 'react';
import * as Constants from './../../store/index';

import  * as Util from './../../data/General';
class DashboardCards extends Component {


    render() {
        const { title, date, second, extra, count, percentage, textTotal=Util.getMessage("home_reviews"), titleTotal=Util.getMessage("home_total_cards"), start= false, addPercentage = "%" } = this.props;
        return(
            <div className="card-home">
                <div className='content-flex space-between-center'>
                    <div className='title'>{title}</div>
                    {date ? (<div className='date'>{Constants.getDate(date)}</div>) : null}
                    {extra ? (<div className='date extra' dangerouslySetInnerHTML={{__html: extra}} />) : null}
                </div>

                <div className='content-flex space-between-center'>
                    <div className='content-flex column'>
                        {!second ? <div className='total'>{titleTotal}</div>: null}
                        {start ? (
                            <div className='review star'>
                                <div className='revieStar'>
                                    <div title={`${count} reseñas`}>
                                        <i data-star={count}></i>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='review'>{count} {textTotal}</div>
                        )}
                        {second ? <>
                            <div className='total'>{second.titleTotal}</div>
                            <div className='review'>{second.count} {second.textTotal}</div>
                        </> : null}
                    </div>
                    <div className='content-flex flex-center'>
                        {/*<div><img src="img/up.png" /></div>*/}
                        {percentage && percentage !== "0" && percentage !== 0 ? (
                            <div className='percentage'>{percentage} {addPercentage}</div>
                        ) : (<div className='percentage'>&nbsp;</div>)}
                        
                    </div>
                </div>
            </div>   
        );}
}
export default DashboardCards;
