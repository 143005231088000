import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '../html/Button';
import * as Constants from './../../store/index';
import * as Util from './../../data/General';
import Point from '../html/Point';
import mixpanel from 'mixpanel-browser';
import HideShow from '../html/HideShow';
import InputHtml from '../html/InputHtml';
import Social from './Social';
import Sentiment from '../html/Sentiment';

class Review extends Component {

    state ={
        isShow: true,
        isSearch: false,
        notify: "",
        urlAdmin: Util.getUrl_domain(),
        textAut: "",
        urlIframe: null,
        configSocial: {
            "background": {
                "type": "background",
                "view": true,
                "label": {
                    "en": "Predefined backgrounds",
                    "es": "Fondos predefinidos",
                    "pt": "Planos de fundo predefinidos"
                },
                "value": "0",
                "default": [
                    {"backgroundinic":"#FF8D83","backgroundfinish":"#DB3E6B"},
                    {"backgroundinic":"#FFC03C","backgroundfinish":"#cc8500"},
                    {"backgroundinic":"#BAF0FA","backgroundfinish":"#83c8d2"},
                    {"backgroundinic":"#FEB4C3","backgroundfinish":"#cf7d8d"},
                    {"backgroundinic":"#47bb9d","backgroundfinish":"#267587"},
                    {"backgroundinic":"#051937","backgroundfinish":"#A8EB12"},
                    {"backgroundinic":"#120024","backgroundfinish":"#ECFFE5"},
                    {"backgroundinic":"#8F7699","backgroundfinish":"#5D6D7E"},
                ]
            },
            "size": {
                "type": "size",
                "view": true,
                "label": {
                    "en": "Size",
                    "es": "Tamaños",
                    "pt": "Tamanho"
                },
                "value": 1,
                "default": {
                    0:{"fontSizeStart":"22","fontSizeTitle":"18","fontSizeMessage": "17"},
                    1:{"fontSizeStart":"25","fontSizeTitle":"22","fontSizeMessage": "20"},
                    2:{"fontSizeStart":"32","fontSizeTitle":"28","fontSizeMessage": "24"},
                }
            },
            "mode": {
                "type": "mode",
                "view": true,
                "label": {
                    "en": "Mode",
                    "es": "Modo",
                    "pt": "Modo"
                },
                "value": 0,
                "default": {
                    0:{"backgroundCard":"#ffffff","colorTitle":"#434343","colorMessage": "#6a6a6a"},
                    1:{"backgroundCard":"#434343","colorTitle":"#ffffff","colorMessage": "#ffffff"}
                }
            },
            "font": {
                "type": "font",
                "view": true,
                "label": {
                    "en": "Typography",
                    "es": "Tipografía",
                    "pt": "Tipografia"
                },
                "value": "Roboto",
                "default": ["Roboto","Poppins","Oswald","Lato","Playfair"]
            },
            "backgroundinic": {
                "type": "color",
                "view": false,
                "label": {
                    "en": "Background Start Color",
                    "es": "Color de Inicio del fondo",
                    "pt": "Cor inicial do plano de fundo"
                },
                "value": "#FF8D83",
            },
            "backgroundfinish": {
                "type": "color",
                "view": false,
                "label": {
                    "en": "Background end color",
                    "es": "Color de fin del fondo",
                    "pt": "Cor final do plano de fundo"
                },
                "value": "#DB3E6B",
            },
            "colorStar": {
                "type": "color",
                "view": false,
                "label": {
                    "en": "Star color",
                    "es": "Color de la estrella",
                    "pt": "Cor da estrela"
                },
                "value": "#ff962f",
            },
            "fontSizeStart": {
                "type": "range",
                "view": false,
                "min": 20,
                "max": 80,
                "step": 1,
                "unit": "px",
                "label": {
                    "en": "Size star",
                    "es": "Tamaño estrella",
                    "pt": "Tamanho estrela"
                },
                "value": 48
            },
            "colorTitle": {
                "type": "color",
                "view": false,
                "label": {
                    "en": "Title color",
                    "es": "Color del título",
                    "pt": "Cor do título"
                },
                "value": "#434343",
            },
            "fontSizeTitle": {
                "type": "range",
                "view": false,
                "min": 20,
                "max": 80,
                "step": 1,
                "unit": "px",
                "label": {
                    "en": "Title size",
                    "es": "Tamaño del título",
                    "pt": "Tamanho título"
                },
                "value": 22
            },
            "colorMessage": {
                "type": "color",
                "view": false,
                "label": {
                    "en": "Message color",
                    "es": "Color del mensaje",
                    "pt": "Cor da mensagem"
                },
                "value": "#6a6a6a",
            },
            "fontSizeMessage": {
                "type": "range",
                "view": false,
                "min": 20,
                "max": 80,
                "step": 1,
                "unit": "px",
                "label": {
                    "en": "Message size",
                    "es": "Tamaño del mensaje",
                    "pt": "Tamanho da mensagem"
                },
                "value": 20
            },
            "backgroundCard": {
                "type": "color",
                "view": false,
                "label": {
                    "en": "Card background color",
                    "es": "Color de fondo de la tarjeta",
                    "pt": "Cor de fundo do cartão"
                },
                "value": "#ffffff",
            }
        }
    }
    setReview = (status) => {
        const { review } = this.props;
        review.state=status;
        console.log(review);
        this.setState({isSearch: true});
        Util.setReview(this.handleOk, this.handleError, review);


        mixpanel.init(window.mixpanelKey); 
        mixpanel.track(status, {
          'orderName': review.orderName,
          'shopId': review.shopOrderId,
          'stars': review.stars,
        });
    }

    setPhoto = (id, type) => {
        const { review } = this.props;
        let isActive = review[type][id]["active"] ? true : false;

        review[type][id]["active"] = !isActive;
        console.log(review);
        this.setState({isSearch: true});
        Util.setReview(this.handleOk, this.handleError, review);   
    }
    favorite= () => {
        const { review } = this.props;
        
        review["favorite"] = !review["favorite"];
        console.log(review);
        this.setState({isSearch: true});
        Util.setReview(this.handleOk, this.handleError, review);   
    }

  handleOk = (data) => {
      console.log(data);
    //this.setState({reviews: data});
    if(data.id){
        
        const { handleOk } = this.props;
        if ( handleOk ) {
            handleOk();
            this.setState({isSearch: false});
        }else{
            this.setState({isShow: false});
        }
    }else{
        this.setState({isSearch: false});
    }
  }

  handleError = (data) => {
    console.log(data);
    //this.setState({reviews: data});
  }

  errorImage = () =>{
    this.setState({notify: Util.getMessage("updateImage")});
    const { updateImage } = this.props;
    if (updateImage){
        const { review } = this.props;
        updateImage(review);
    }
  }

  playText = () => {
    const { review } = this.props;
    window.countTextAut = 0;
    this.setState({textAut : "("+Util.getMessage("gen_aut")+") "});
    
    setTimeout(function(){
        this.typeWriter(review.comment);
    }.bind(this), 170);
  }

    typeWriter = (comment) => {
        if (window.countTextAut < comment.length) {
            console.log(window.countTextAut);
            let { textAut } = this.state;
            textAut += comment.charAt(window.countTextAut);
            this.setState({textAut : textAut});
        window.countTextAut++;
        setTimeout(function(){
            this.typeWriter(comment);
            }.bind(this), 50);
        }
  };

    openEdit = () => {     
        this.setState({urlIframe: "true"});
        let configSocial = localStorage.getItem(Constants.storage.USER_SOCIAL_CONFIG);
        if(configSocial){
            configSocial = JSON.parse(configSocial);
            this.setState({configSocial: configSocial});
        }
        setTimeout(function(){   
            var menu = document.getElementById("menu");
            menu.style.zIndex= -1;
            var modal = document.getElementById("myModal");
            modal.style.display = "block";
            
            window.onclick = function(event) {
                if (event.target == modal) {
                    modal.style.display = "none";
                    menu.style.zIndex= 99;
                }
            } 
        }.bind(this), 200);   
        setTimeout(function(){
            const { review} = this.props;
            mixpanel.init(window.mixpanelKey); 
            mixpanel.track("SOME OPEN", {
                'orderName': review.orderName,
                'shopId': review.shopOrderId,
                'stars': review.stars,
            });
        }.bind(this), 500); 
    }
    donwloandSocial = () => {
        const { review} = this.props;
        const { configSocial} = this.state;


        let image = "";
        if(review.images && review.images.length > 0){
            image = review.images[0].src;
        }else if(review.product.images && review.product.images.length > 0){
            image = review.product.images[0].src;
        }
        if(image !== ""){
            let urlSpecial = window.url_server_2+"social/?title="+encodeURIComponent(review.product.name)+"&message="+encodeURIComponent(review.comment)+"&start="+review.stars+"&image="+(image)+"&b1="+Util.hexToRgb(configSocial.backgroundfinish.value)+"&b2="+Util.hexToRgb(configSocial.backgroundinic.value)+"&ct="+encodeURIComponent(configSocial.colorTitle.value)+"&ft="+configSocial.fontSizeTitle.value+"&cm="+encodeURIComponent(configSocial.colorMessage.value)+"&fm="+configSocial.fontSizeMessage.value+"&cs="+encodeURIComponent(configSocial.colorStar.value)+"&fs="+configSocial.fontSizeStart.value+"&bc="+encodeURIComponent(configSocial.backgroundCard.value)+"&font="+configSocial.font.value;
            
            this.setState({urlIframe: urlSpecial});  
            setTimeout(function(){
                this.setState({urlIframe: "true"});  
            }.bind(this), 12000);

            setTimeout(function(){
                localStorage.setItem(Constants.storage.USER_SOCIAL_CONFIG, JSON.stringify(configSocial));
                mixpanel.init(window.mixpanelKey); 
                mixpanel.track("SOME DOWNLOAD", {
                    'orderName': review.orderName,
                    'shopId': review.shopOrderId,
                    'stars': review.stars,
                });
            }.bind(this), 500);
        }

    }
    closeModal= () => {
        var modal = document.getElementById("myModal");
        modal.style.display = "none";
        var menu = document.getElementById("menu");
        menu.style.zIndex= 99;
        this.setState({urlIframe: null});
    }
    onUpConf  = (value, name) => {
        const { configSocial } = this.state;
        if(name === "background"){
            let key0 = Object.keys(value)[0];
            configSocial[key0].value = value[key0];
            let key1 = Object.keys(value)[1];
            configSocial[key1].value = value[key1]; 
        }else if(name === "size" || name === "mode"){
             let newValue = value + 1; 
             if(Object.keys(configSocial[name].default).length <= newValue){
                newValue = 0;
             }
             configSocial[name].value = newValue;

             let jsonConf = configSocial[name].default[newValue];

             Object.keys(jsonConf).map(
                (config) => {
                    configSocial[config].value = jsonConf[config];
                })
        }else{
            configSocial[name].value = value; 
        }
        console.log(configSocial);
        this.setState({configSocial: configSocial});
    }
    showSocial = (config) => {
        const { configSocial } = this.state;
        configSocial[config].view = !configSocial[config].view;
        this.setState({configSocial: configSocial});
    }
    goToPedding = () => {
        this.props.dispatch({
            type: 'CHANGE_MENU',
            new_option: "/plan"
        });
    }

    render() {
        const { review, isShowPoints = true, openModal, isPay } = this.props;
        const { isShow, isSearch, notify, urlAdmin, textAut, urlIframe, configSocial } = this.state;
        
        return (
            <div className={`content-review content-review-item ${isShow ? '' : 'no-visible'}`}>
                <div className='content-flex flex-direction-column-mobile'>
                    <div className='content-flex flex1'>
                        <div className='content-img'>
                            {review.product.images && review.product.images.length > 0 ? (
                                <img src={Util.getUrlImageWidth(review.product.images[0].src)} 
                                    loading="lazy"
                                    alt="img product"
                                    onError={(e) => {
                                        e.currentTarget.onerror = null;
                                        e.currentTarget.src = 'img/error.png';
                                        this.errorImage();
                                    }} 
                                />
                            ) : (
                                <img 
                                    loading="lazy"
                                    alt="img product"
                                    src='img/error' 
                                    onError={(e) => {
                                        e.currentTarget.onerror = null;
                                        e.currentTarget.src = 'img/error.png';
                                        this.errorImage();
                                    }} 
                                />
                            )}
                            {notify !== "" && (<span className='update-image'>{notify}</span>)}
                        </div>
                        <div className='content-flex column'>
                            <div className='name'>{review.product.name}</div>
                            {isShowPoints && (<Point point={review.stars} />)}
                            <div className='order'>
                                <a href={`${urlAdmin}orders/${review.shopOrderId}`}  target="_blank">
                                    ORDER {" "} {review.orderName}
                                </a>
                            </div>
                            <div className='date'>{Constants.getDate(review.createdAt)}</div>
                            {review.discount && (
                                <div className='name'>
                                    <small>{Util.getMessage("discount_code")}: </small> <a href={`${urlAdmin}discounts?query=${review.discount.code}`}  target="_blank"><b>{review.discount.code}</b></a>
                                </div>
                            )}

                            {isPay && review.sentimentState && (
                                <Sentiment sentiment={review.sentimentState} />
                            )}

                        </div>
                    </div>
                    <div className='flex2'>
                        <div className='customer-name'>
                            {review.customer.firstName + " " +review.customer.lastName}
                            {review.customer && review.customer.phone && (
                                <a href={`https://wa.me/${review.customer.phone.replace("+", "")}/`}  target="_blank">
                                    <img loading="lazy" alt="whatsapp" className='icon-links' src="img/whatsapp.png" />
                                </a>
                            )}
                            {review.customer && review.customer.email && (
                                <a href={`mailto:${review.customer.email}`}  target="_blank">
                                    <img loading="lazy" alt="email" className='icon-links' src="img/email.png" />
                                </a>
                            )}
                            {((review.images && review.images.length  > 0) || (review.product.images && review.product.images.length > 0)) && (
                                <img className='icon-links no-mobile' onClick={() => this.openEdit()} src='img/share64.png' alt="share64" title={Util.getMessage("title_img_social")}/>
                            )}
                            {(review.favorite) ? (
                                <img className='icon-links no-mobile' onClick={() => this.favorite()} src='img/favorite-active.png' alt="Favorite ok" title={Util.getMessage("title_img_favorite_ok")}/>
                            ) : (
                                <img className='icon-links no-mobile' onClick={() => this.favorite()} src='img/favorite.png' alt="Favorite" title={Util.getMessage("title_img_favorite")}/>
                            )}
                            {/*!isPay && (
                                <img className='icon-links hidden' src='img/share64.png' alt="share64" title={Util.getMessage("title_img_social_hidden")}/>
                            )*/}
                        </div>
                        <div className='comment'>
                            {!(review.audio && review.audio.length > 0 && review.comment && review.comment.length > 10) ? (
                                <>{review.comment}</>
                            ) : (
                                <i>{textAut}</i>
                            )}
                        </div>
                        <div className='content-flex space-between-center buttons content-image-scroll'>
                            <div className='content-flex image-scroll'>
                                {review.images && review.images.length > 0 && review.images.map((item, key) => (
                                    <div key={key} className="item-review">
                                        <img loading="lazy" src={Util.convertMediaRevie(item.src)} alt={review.comment} className={item["active"] === false ? 'disabled' : ''} onClick={() => openModal(item.src, review.comment)}></img>
                                        <HideShow active={item["active"]} type="images" index={key} onclick={this.setPhoto} />
                                    </div>
                                ))}

                                {review.audio && review.audio.length > 0 && review.audio.map((item, key) => (
                                    <div key={key} className="item-review">
                                        <audio controls className={item["active"] === false ? 'disabled' : ''} >
                                            <source src={Util.convertMediaRevie(item.src)} type="audio/ogg" />
                                            Your browser does not support the audio element.
                                        </audio>
                                        <HideShow className={"audio"} active={item["active"]} type="audio" index={key} onclick={this.setPhoto} />
                                        {review.comment && review.comment.length > 10 && (
                                            <div className={`content-convert ${item["active"] === false ? 'disabled' : ''}`} onClick={() => this.playText()}>
                                                {Util.getMessage("gen_aut_bottom")}
                                                <img loading="lazy" src="/img/voz.png" className="img-convert" />
                                            </div>
                                        )}
                                    </div>
                                ))}

                                {review.video && review.video.length > 0 && review.video.map((item, key) => (
                                    <div key={key} className="item-review">
                                        <div  onClick={() => openModal(Util.convertMediaRevie(item.src), review.comment, "video")} >
                                            <img loading="lazy" src="/img/video.png" className={item["active"] === false ? 'disabled' : ''} />
                                        </div>
                                        <HideShow active={item["active"]} type="video" index={key} onclick={this.setPhoto} />
                                    </div>
                                ))}
                            </div>
                            <div className='content-flex'>
                                {review.state === "PENDING_APPROVAL" ? (
                                    <>
                                        <Button value={Util.getMessage("hide")} loader={isSearch} image="img/cancel.png" onClick={() => this.setReview("REJECTED")}/>
                                        <Button value={Util.getMessage("publish")} loader={isSearch} image="img/check.png" type="primary" onClick={() => this.setReview("APPROVED")}/>
                                    </>
                                ) : review.state === "REJECTED" ? (
                                    <>
                                        <Button value={Util.getMessage("publish")} loader={isSearch} image="img/check-button.png" onClick={() => this.setReview("APPROVED")}/>
                                    </>
                                ) : review.state === "APPROVED" && (
                                    <>
                                        {/*review.images && review.images.length > 0 && (
                                            <Button value={Util.getMessage(review.images[0]["active"] === false ? "show_image" : "hide_image")} loader={isSearch} image={Util.getMessage(review.images[0]["active"] === false ? "img/check.png" : "img/cancel.png")} type={Util.getMessage(review.images[0]["active"] === false ? "quarter" : "third")} onClick={() => this.setPhoto()}/>
                                        )*/}
                                        <Button value={Util.getMessage("hide")} loader={isSearch} image="img/cancel.png" onClick={() => this.setReview("REJECTED")}/>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    {urlIframe && (
                        <div id="myModal" className="modal-social-popup" >
                            <div className="modal-content">
                                <div className="modal-body">
                                <span className="close" onClick={() => this.closeModal()}>×</span>
                                <Social configSocial={configSocial} review={review} isPay={isPay}/>
                                    <div style={{"width": "330px", "margin" : "0rem 2rem 2rem 2rem","height": "680px"}}>
                                        <div style={{"overflow": "auto","position": "relative", "marginBottom": "1rem"}}>
                                        {!isPay && (<div className='update-plan-name' style={{margin: "0rem 0rem 1rem 0rem",display: "block"}}> {Util.getMessage("PlanSupper200")} <a href="#" onClick={() => this.goToPedding()}>{Util.getMessage("update_plan")}</a></div>)}
                                            {Object.keys(configSocial).map(
                                                (config, index) => (
                                                    <div key={index}>
                                                        {configSocial[config].view ? (
                                                            <div className='text'>
                                                                <div className='text social-item social-item-show' onClick={() => this.showSocial(config)}>
                                                                    {configSocial[config].type === "text" ? Util.getMessage("TEXT_CONFIG") : configSocial[config].label[window.paramRevie.language]}
                                                                </div>
                                                                <InputHtml defaultElement={configSocial[config].default} unit={configSocial[config].unit} type={configSocial[config].type} min={configSocial[config].min} max={configSocial[config].max} edit={true} name={config} value={configSocial[config].value} onBlur={this.onUpConf}/>
                                                            </div>
                                                        ) :(
                                                            <div className='text social-item social-item-hide' onClick={() => this.showSocial(config)}>
                                                                {configSocial[config].type === "text" ? Util.getMessage("TEXT_CONFIG") : configSocial[config].label[window.paramRevie.language]}
                                                                    <img src='img/conf.png' alt="show" />
                                                            </div>
                                                    )}
                                                    </div>
                                            ))}
                                            <div className="content-flex buttons">
                                                {isPay && (<Button value={Util.getMessage("saveSocial")} disabled={urlIframe !== "true"} loader={urlIframe !== "true"} type="primary" onClick={() => this.donwloandSocial()}/>)}
                                                <Button value={Util.getMessage("close")}  onClick={() => this.closeModal()}/>
                                            </div>
                                        </div>
                                        
                                        {urlIframe !== "true" && (
                                            <>
                                                <iframe src={urlIframe} className="iframe-content" />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>  
                    )}

                </div>
            </div> 
        );
  }
}
const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Review);
