import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Util from './../data/General';

import mixpanel from 'mixpanel-browser';
import AnalyticsCampaigns from './AnalyticsCampaigns';

class CampaignsCheck extends Component {

    state = {
      isShowPlus: false,
      addScript: true, 
      isOk: false,
      isCreateTwilio: false,
      phone_number_id: null, 
      waba_id: null
    };

    
    componentDidMount() {

      //Util.checkCampaigns(this.checkOk,this.checkError);
      /*var shop = Util.getAdminShop();
      if (shop){
        if(shop.enabledCampaigns){
          this.setState({isShowPlus: true});
        }
      }*/
      this.checkOk();
    }

    checkOk = () => {
      const { checkFacebook= false } = this.props;
      if(!checkFacebook){
        let shopJson =  Util.getAdminShop();
        if(shopJson){
            
            if(shopJson.enabledCampaigns){
              this.setState({isOk: true});
            }else if(shopJson.plan && shopJson.plan.planType && shopJson.plan.planType === "PLUS"){
              this.import();
            }else{
              mixpanel.init(window.mixpanelKey); 
              mixpanel.track('Advertising_Campaigns', {
                'shopDomain': shopJson.domain,
                  'shopId': shopJson.id,
              });
            }

            //window.loadFacebook();
        }
      }else{
        this.setState({isCreateTwilio: true});
        setTimeout(function(){window.loadFacebook();}.bind(this), "1200");
        window.intervalSet = setInterval(function(){
          if(window.waba_id && window.phone_number_id){
            this.setState({waba_id: window.waba_id, phone_number_id: window.phone_number_id});
            clearInterval(window.intervalSet);
            window.intervalSet = null;
          }
      }.bind(this), 2000);
      }
    }

    checkError = (data) => {
      
    }

    goToPedding = () => {
      
      this.props.dispatch({
        type: 'CHANGE_MENU',
        new_option: '/plan'
      });
  }

  import = () => {
    const { addScript } = this.state;
    if(addScript){
        let scriptLoader = document.getElementById("script");
        if(!scriptLoader){
            let script = document.createElement("script");
            script.src = "https://tally.so/widgets/embed.js";
            script.id = "script";
            document.head.append(script);   
            this.setState({addScript: false, isShowPlus: true});
        }
    }
    setTimeout(function(){
        
    }.bind(this), 400);
}

onclick=() => {
  window.launchEmbeddedSignup();
}
    
  
    render() {
      const { isShowPlus, isOk, isCreateTwilio, phone_number_id, waba_id } = this.state;
      if(isOk){
        return (<AnalyticsCampaigns />);
      }else if(isCreateTwilio){
        return (<div>
          {phone_number_id ? (
            <div className="create-flow content-reviews content-segments-ia">
              <div className="title content-flex space-between space-top">
              Felicidades, la integración se realizó con éxito.
              </div>
              <div className="title content-flex space-between space-top">
               WABA ID: {waba_id}
              </div>
              <div className="title content-flex space-between space-top">
               Phone Number ID: {phone_number_id}
              </div>
            </div>
          ) : (
            <>
            <div className="create-flow content-reviews content-segments-ia">
              <div className="title content-flex space-between space-top">
              A continuación, haz clic para integrar tu número de whatsapp a Revie
              </div>
            </div>
            <div className="title content-flex space-between space-top" style={{justifyContent: "center"}}>
              <button onClick={() => this.onclick()} style={{backgroundColor: "#1877f2", border: "0", borderRadius: "4px", color: "#fff", cursor: "pointer", fontFamily: "Helvetica, Arial, sans-serif", fontSize: "16px", fontWeight: "bold", height: "40px", padding: "0 24px"}}>
              Login with Facebook
              </button>
            </div>
            </>
          )}

        </div>);
        
      }else if(!isShowPlus){
        return (
          <div className='content-reviews promo-campaings'>
            <div className='title' dangerouslySetInnerHTML={{__html: Util.getMessage("page_camp_title")}}  />
            <div className='title' dangerouslySetInnerHTML={{__html: Util.getMessage("page_camp_title_1")}}  />
            <div className='content-flex'>
              <img src='img/1.jpg' />
              <img src='img/2.jpg' />
            </div>
            <div className='text' dangerouslySetInnerHTML={{__html: Util.getMessage("page_camp_text2")}}  />
            <div className='text' dangerouslySetInnerHTML={{__html: Util.getMessage("page_camp_text3")}}  />
            <ul>
            <li className='text' dangerouslySetInnerHTML={{__html: Util.getMessage("page_camp_text4")}}  />
            <li className='text' dangerouslySetInnerHTML={{__html: Util.getMessage("page_camp_text5")}}  />
            <li className='text' dangerouslySetInnerHTML={{__html: Util.getMessage("page_camp_text6")}}  />
            </ul>
            <div className='text' dangerouslySetInnerHTML={{__html: Util.getMessage("page_camp_text7")}}  />

            <div className='update-plan-name' style={{width: "110px"}}><a href="#" onClick={() => this.goToPedding()}>{Util.getMessage("update_plan")}</a></div>
            <div dangerouslySetInnerHTML={{__html: Util.getMessage("page_camp_buton")}}  />
          </div>
        );
      } else{
        return (<iframe data-tally-src="https://tally.so/r/mR0yEp?transparentBackground=1" width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0" title="Marketing x Whatsapp"></iframe>);
      }
      }
    }
const mapStateToProps = (state) => ({
    shop: state.shop,
    filter: state.filter
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignsCheck);
