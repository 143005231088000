import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Util from './../data/General';
import ListReviews from '../components/widget/ListReviews';
import Tabs from '../components/html/Tabs';
import Page from '../components/html/Page';

import mixpanel from 'mixpanel-browser';
import SelectStar from '../components/html/SelectStar';
import SelectFilter from '../components/html/SelectFilter';
import SelectInput from '../components/html/SelectInput';

class Reviews extends Component {

    state = {
      reviews: null,
      tabs: [Util.getMessage("PENDING_APPROVAL_REVIEWS"), Util.getMessage("APPROVED"), Util.getMessage("REJECTED"),Util.getMessage("ALL")],
      tabsState: ["PENDING_APPROVAL", "APPROVED", "REJECTED","PENDING_APPROVAL,APPROVED,REJECTED"],//"NEW,SENT"
      tabSelectedName: Util.getMessage("PENDING_APPROVAL_REVIEWS"),
      filterState: null,
      isSearch: false,
      tabSelect: null,
      shop: null,
      stars: "",
      isPay: false,
    };

    
    componentDidMount() {

      let filter = {...Util.filterInic};
      filter.state="PENDING_APPROVAL";

      this.setFilter(filter);
      var shopJson =  Util.getAdminShop();
      if (shopJson){
        
        let isPay = (shopJson.plan && shopJson.plan.planType !== "50" && shopJson.plan.planType !== "0");
        this.setState({shop: shopJson, isPay: isPay});
        mixpanel.init(window.mixpanelKey); 
        mixpanel.track('RE Pendientes', {
          'shopDomain': shopJson.domain,
          'shopId': shopJson.id,
        });
      }
    }

    setFilter = (filter, isSearch=true) =>{
      this.setState({filterState: filter, isSearch: isSearch});
      Util.getReviews(filter, this.handleOk, this.handleError);
    }

    handleOk = (data) => {
      this.setState({reviews: data, isSearch: false});
    }

    handleError = (data) => {
      //this.setState({reviews: data});
    }

    handleChangePage = (page) => {

      const { filterState } = this.state;
      filterState["size"] = page;
      this.setFilter(filterState);
      return false;  
  }  

  goTo = (from) => {
      const { filterState } = this.state;
      filterState.from = from;
      this.setFilter(filterState);
  }

  update = () => {
    const { filterState } = this.state;
    this.setFilter(filterState, false);
}

onChangeStar = (value) => {
  const { filterState } = this.state;
  filterState.from = 0;
  filterState["stars"] = (value !== "" && value !== 0 ? value : null);
  this.setState({stars: value});
  this.setFilter(filterState);
  return false;  
}

onChangeFilters = (value, name) => {
  const { filterState } = this.state;
  filterState.from = 0;
  filterState[name] = value;
  //this.setState({stars: value});
  this.setFilter(filterState);
  return false;  
}

onChangeFilter = (value, name) => {
  return this.onChangeFilters((value ? "true" : null), name); 
}

onChangeFilterState = (value, name) => {
  return this.onChangeFilters((value ? name : null), "sentimentState"); 
}

onChangeFilterSearch = (value, name) => {
  const { filterState } = this.state;
  if((!filterState["text"] && value !== "") || (filterState["text"] && value !== filterState["text"])){
    return this.onChangeFilters((value !== "" ? value : null), "text"); 
  }
  return false;
}

    handleClickTab = (tab) => {
      const { tabs, tabsState, filterState, shop } = this.state;
      this.setState({tabSelectedName: tabs[tab], isSearch: true, tabSelect: tab});
      filterState.state=tabsState[tab];
      Util.getReviews(filterState, this.handleOk, this.handleError);
      mixpanel.init(window.mixpanelKey); 
      mixpanel.track('RE '+tabs[tab].toLowerCase(), {
        'shopDomain': shop.domain,
        'shopId': shop.id,
      });

    }
  
    render() {

      const { reviews, tabs, tabSelectedName, isSearch, tabSelect, tabsState, filterState, isPay } = this.state;
        return (
            <div className="content-reviews">
                <div className='title content-flex space-between'>
                  {Util.getMessage("list_reviews_reviews") + " " + Util.getMessage(tabSelectedName)}
                  {/*<SelectHtml options={Util.STARS} name="stars" value={stars} onChange={this.onChangeStar}/>*/}
                  <div className="content-filter-reviews">
                    <SelectStar onChange={this.onChangeStar}/>
                    <div className="content-flex">
                      <SelectFilter titleHover="imagetooltip" name="images" onChange={this.onChangeFilter} img="photo"/>
                      <SelectFilter titleHover="audiotooltip" name="audio" onChange={this.onChangeFilter} img="audio"/>
                      <SelectFilter titleHover="videotooltip" name="video" onChange={this.onChangeFilter} img="videof"/>
                      <SelectFilter titleHover="positivotooltip" name="POSITIVE" onChange={this.onChangeFilterState} state="POSITIVE"/>
                      <SelectFilter titleHover="neutraltooltip" name="NEUTRAL" onChange={this.onChangeFilterState} state="NEUTRAL"/>
                      <SelectFilter titleHover="negativotooltip" name="NEGATIVE" onChange={this.onChangeFilterState} state="NEGATIVE"/>
                      <SelectFilter titleHover="mixedtooltip" name="MIXED" onChange={this.onChangeFilterState} state="MIXED"/>
                      <SelectInput name="text" onChange={this.onChangeFilterSearch} img="lupa"/>
                    </div>
                  </div>
                </div>
                <Tabs tabs={tabs} handleClick={this.handleClickTab}/>
                {isSearch ? (
                  <div className='search'>
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                  </div>
                ) : (
                  <>
                    <ListReviews reviews={reviews} isPay={isPay} isShowTitle={false} title={Util.getMessage("list_reviews_reviews") + " " + Util.getMessage(tabSelectedName)} isShowPoints={tabsState[tabSelect] !=="NEW"} handleOk={this.update}/> 
                    {reviews && reviews.length > 0 && (
                      <Page from={filterState.from} size={filterState.size} elementSize={reviews.length} handleClick={this.goTo} handleChangePage={this.handleChangePage}/>
                    )}  
                    <div className='spacing'></div>
                  </>
                )}
            </div>
        );
        }
    }
const mapStateToProps = (state) => ({
    shop: state.shop,
    filter: state.filter
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reviews);
