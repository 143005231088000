import React, { useState, useEffect } from 'react';

import * as Util from '../../data/General';
import InputHtml from './InputHtml';
import TextAreaHtml from './TextAreaHtml';
import UploadImages from './UploadImages';

function CardTemplate(props) {
  /*const { values, selected, onChange, name, theme } = props;
  const [value, setValue] = useState(null);
  const [label, setLabel] = useState(null);
  const [show, setShow] = useState(false);

  let upperTheme = theme;
  if (upperTheme != null){
    upperTheme = upperTheme.toUpperCase();
  }
  
  useEffect(() => {
    let isOptions = false;
    {values && values.map((val, index) => {
        if(val.value === selected){
          setValueNotific(val);
          isOptions = true;
        }
    })}

    if(!isOptions && !value && values && values.length > 0) {
      setValueNotific(values[0]);
    }
  },[]);

  const setValueNotific = (item) => {
    setValue(item.value);
    setLabel(item.label);
    if(onChange){
      onChange(item.value, name );
    }
  }
*/

  const { index, card, onBlurCampaing, loading} = props;

  const onBlurCampaingCard  = (value, name) => {
    card[name] = value;
    onBlurCampaing(card, index);
  }
  const onBlurCampaingCardAction  = (value, name) => {
    card.actions[0][name] = value;
    onBlurCampaing(card, index);
  }

  const generateIA = (value) => {
    
  }
 
  return (
      
    <div className='wa-card'>
      <div className='text'>
        <h2>{Util.getMessage("form_4_bis_2")}</h2>
        <InputHtml className="" placeholder="" name="title" value={card.title} onBlur={onBlurCampaingCard} />
      </div>
      <div className='text'>
        <h2>{Util.getMessage("form_3_bis_2")}</h2>
        <UploadImages onBlur={onBlurCampaingCard} name="media" value={card.media} />  
      </div>
      <div className='text' style={{"position": "relative"}}>
        <h2>{Util.getMessage("form_2")}</h2>
        {loading ? (
          <div className="presentacion" >
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          </div>
          ) : (
            <TextAreaHtml className="" edit={true} name="body" value={card.body} onBlur={onBlurCampaingCard} generateIA={generateIA} />
          )
        }
        
      </div>
      <div className='text'>
        <h2>{Util.getMessage("form_5")}</h2>
        <InputHtml className="" placeholder="Type button text" name="title" value={card.actions.title} onBlur={onBlurCampaingCardAction}/>
      </div>
      <div className='text'>
        <h2>{Util.getMessage("form_6")}</h2>
        <InputHtml className="" placeholder="https://" name="url" value={card.actions.url} onBlur={onBlurCampaingCardAction} />
      </div>
    </div>
);
}

export default CardTemplate;