import React, { Component } from 'react';
import * as Constants from './../../store/index';
import Axios from 'axios';
import {SUCCESS} from '../../components/svg/animate';

import * as Util from './../../data/General';
import Button from './Button';


class UploadImages extends Component {

    state = { 
        selectedFile: null, 
        dataImageNew : null,
        isSearch: false,
        type: "image",
        format: "",
        id: "file"+Math.floor(Math.random() * 999),
    };

    componentDidMount() {
        
    }

    // On file select (from the pop up)
    onFileChange = event => {
        this.setState({ selectedFile: event.target.files[0] });
        var reader = new FileReader();
        reader.onload = function (e) {
            this.setState({dataImageNew : e.target.result});
            var match = e.target.result.match(/^data:([^/]+)\/([^;]+);/) || [];
            var type = match[1];
            var format = match[2];
            this.setState({type : type, format: format});
        }.bind(this);
        
        

        reader.readAsDataURL(event.target.files[0]);
        
        setTimeout(function(){
            this.onFileUpload();
          }.bind(this), 1500);
      };
      
      // On file upload (click the upload button)
      onFileUpload = () => {
        const { customerId, upload, onBlur, name } = this.props;
        this.setState({isSearch: true});
        // Create an object of formData
        const formData = new FormData();
      
        // Update the formData object
        formData.append(
            "file",
            this.state.selectedFile,
            this.state.selectedFile.name,        
        );
      
        // Details of the uploaded file
        //console.log(this.state.selectedFile);
      
        // Request made to the backend api
        // Send formData object
        const url = Constants.URL_API+"marketing/campaigns/uploadimage";
        //Axios.post(url, formData);

        Axios.post(url, formData, Constants.getHeaders()).then(response => response.data,error => {
            console.log(error.response);
            if (error.response.status === 401 || error.response.status === 400 || error.response.status === 403  || error.response.status === 404) {
                this.setState({ errorText: Constants.getMessage(error.response.data.message), isSearch: false});
            }
            return error;
          }).then((data) => {
            //console.log(data);    
            if (upload){
                upload(data);
            }    
            if(onBlur){
                onBlur(data, name);
                this.setState({ imageuploadOk: true });
            }  
            setTimeout(function(){
                this.setState({isSearch: false, imageuploadOk: false, selectedFile: null, dataImageNew : null});//dataImageNew : null
              }.bind(this), 5000);
            
        });
      };

      chance = () => {
        const { onBlur, name } = this.props;
        if(onBlur){
            onBlur("", name);
        }  
      }

      errorUpload = (e) => {
        console.log(e);
        console.log(e.type);
      }

    render() {
        const { imageuploadOk, dataImageNew, isSearch, type, id } = this.state;
        const { value, isPreview = false, text_btn="btn_change" } = this.props;

        return (
            <div className="content-upload-image">
                <div>
                    {imageuploadOk ? (
                        <div className='title content-flex space-between space-top'>
                            <div className='content-flex flex-start' style={{gap: "1rem"}}>
                                <div dangerouslySetInnerHTML={{__html: SUCCESS}} /> {Util.getMessage("successe_upload_image")}
                            </div>
                        </div>
                    ) : (value && value !== "") ? 
                    <div className='content-flex flex-start' style={{gap: "1rem"}}>
                        {isPreview ? <img src={value} alt="preview" /> : null}
                        <Button 
                            value={Util.getMessage(text_btn)} 
                            type="primary" 
                            onClick={() => this.chance()}/>
                    </div> 
                    : dataImageNew ? (
                        <div>
                            {type === "image" ? (
                                <img src={dataImageNew} alt="preview" onError={(e) => {this.errorUpload(e)}}/>
                            ) : type === "video" ? (
                                <video style={{width: "200px"}} autoplay="1" muted><source src={dataImageNew} type="video/mp4"/></video>
                            ) : null}
                            
                        </div>
                    ) : (
                        <label htmlFor={id} className='label-file'>
                            <div>
                                <span className="formbold-drop-file"> {Util.getMessage("DropFile")}</span>
                                <span className="formbold-or"> O </span>
                                <span className="formbold-browse"> {Util.getMessage("SelectFile")} </span>
                            </div>
                        </label>
                    )}
                    <input type="file" id={id} style={{"display": "none"}} multiple accept="image/*, video/*" onChange={this.onFileChange}/>
                </div>
                {false && (!imageuploadOk && !(value && value !== "") )? (
                <Button 
                        value={Util.getMessage("btn_upload_image")} 
                        disabled={!dataImageNew || isSearch} 
                        type="primary" 
                        onClick={() => this.onFileUpload()}/>
                ) : null}
            </div>
        );
  }
}

export default UploadImages;