import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import * as Constants from './../store/index';
import  * as Util from './../data/General';

import { connect } from 'react-redux';
import RadioHtml from '../components/html/RadioHtml';
import mixpanel from 'mixpanel-browser';
import SelectHtml from '../components/html/SelectHtml';

class Presentation extends Component {

  state ={
    isAvailable : true,
    step:-1,
    
    shop: null
  }
  
  componentDidMount() {
    Util.getStatestatistics({}, this.handleOkState, this.handleErrorState);
    const { shop, login } = this.props;
    //console.log(shop);
    if(shop){
        shop.daysNotify='10';
        shop.daysOrders='60';
        this.setState({shop: shop});
        mixpanel.init(window.mixpanelKey); 
        mixpanel.track('Onboarding', {
          'shopDomain': shop.domain,
          'shopId': shop.id,
        });
    }
    setTimeout(function(){
      let log = login.step;
      if(log === 0){
        log = 1;
      }
      this.setState({step: log});
    }.bind(this), 50);
    /*setTimeout(function(){
      this.setState({isAvailable: true});
    }.bind(this), 800);
    */
    
  }
  handleOkState = (data) => {
    let isInstall = false;
    if(data && data.length > 0){
      data.map((item, key) => {
        console.log(item);
        if(item.count > 0){
          isInstall = true ;
        }
      });
      if (isInstall){
        const { login } = this.props;
        login.step = 4;
        login.install= true;
        localStorage.setItem(Constants.storage.USER_INSTALL_ADMIN, JSON.stringify(login));
        window.location.href = '/';//TODO: ACA se recarga
      }
    }
  }

  handleErrorState = (data) => {
    console.log(data);
  }
  handleClick = () => {
    const { shop } = this.state;
    Util.setInfoShop(this.handleOkShop, this.handleOkShoperror, shop);
    this.setState({isAvailable: false});
    mixpanel.init(window.mixpanelKey); 
    mixpanel.track('ON Finalizar', {
      'shopDomain': shop.domain,
      'shopId': shop.id,
    });
  }

  onChange = (value, name) => {
    const { shop } = this.state;
    shop[name] = (value !== "" ? value : null); 
    console.log(shop);
    this.setState({shop: shop});
    //Util.setInfoShop(this.handleOkShop, this.handleOkShoperror, shop);
    if("lang" === name){
      Util.setLanguage(shop, true);
    }
    localStorage.setItem(Constants.storage.USER_SHOP, JSON.stringify(shop));
}

handleOkShop = (data) => {
  if (data && data.id){
      localStorage.setItem(Constants.storage.USER_SHOP, JSON.stringify(data));
      const { login } = this.props;
      login.step = 4;
      //login.install= true;
      localStorage.setItem(Constants.storage.USER_INSTALL_ADMIN, JSON.stringify(login));
      window.location.href = '/';

      //this.setState({isAvailable: true});
  }
}

handleOkShoperror = (data) => {
  console.log(data);
  this.setState({isAvailable: true});
}

setStep = (newStep) => {
  const { shop } = this.state;
  this.setState({step: newStep})
  mixpanel.init(window.mixpanelKey); 
  mixpanel.track(newStep === 1 ? 'ON Paso 1' : (newStep === 2 && 'ON Paso 2'), {
    'shopDomain': shop.domain,
    'shopId': shop.id,
  });
  const { login } = this.props;
  login.step = newStep;
  localStorage.setItem(Constants.storage.USER_INSTALL_ADMIN, JSON.stringify(login));
}

  render() {
    const { isAvailable, step, shop } = this.state;
    if (step !== -1){
      return (
        <div className="presentacion">
          <img src="/img/logo-black.png" className="logoBlack" alt="logo Revie" />
          {step === 0 ? (
            <div>
              <h2 className="frame-1">{Util.getMessage("ONBOARDING_title")}</h2>
              <h2 className="frame-2">{Util.getMessage("ONBOARDING_sub_title")}</h2>
              <div><img src="/img/press.gif" className="gif" alt="logo Revie" /></div>
              <button disabled={!isAvailable} className="btn btn-dark" onClick={() => this.setStep(1)}>{Util.getMessage("ONBOARDING_button")}</button>
            </div>
          ) : step === 1 ? (
            <div>
              <div className='step'>
                <div className='active'>1</div>
                <div className='line'></div>
                <div className=''>2</div>
                <div className='line'></div>
                <div className=''>3</div>
              </div>
              <h2 className="frame-2">{Util.getMessage("ONBOARDING_step_1")} 1</h2>
              <h2 className="frame-2">{Util.getMessage("ONBOARDING_step_1_lang")}</h2>
              <div className='radios'>
                <SelectHtml options={Util.LANG} name="lang" value={shop.lang} onChange={this.onChange}/>
              </div>
              <button disabled={!isAvailable} className="btn btn-dark" onClick={() => this.setStep(2)}>{Util.getMessage("ONBOARDING_button_1")}</button>
            </div>
          ) : step === 2 ? (
            <div>
              <div className='step'>
                <div className='active'>1</div>
                <div className='line'></div>
                <div className='active'>2</div>
                <div className='line'></div>
                <div className=''>3</div>
              </div>
              <h2 className="frame-2">{Util.getMessage("ONBOARDING_step_1")} 2</h2>
              <h2 className="frame-4">{Util.getMessage("ONBOARDING_step_1_title")}</h2>
              <h2 className="frame-5">{Util.getMessage("ONBOARDING_step_1_sub_title")}</h2>
              <div className='radios'>
                <RadioHtml options={Util.DAYS_INSTALL} type="checkbox" value={shop.daysNotify} name="daysNotify" onChange={this.onChange}/>
              </div>
              <button disabled={!isAvailable} className="btn btn-dark" onClick={() => this.setStep(3)}>{Util.getMessage("ONBOARDING_button_1")}</button>
            </div>
          ) : (
            <div>
              <div className='step'>
                <div className='active'>1</div>
                <div className='line'></div>
                <div className='active'>2</div>
                <div className='line'></div>
                <div className='active'>3</div>
              </div>
              
              <h2 className="frame-2">{Util.getMessage("ONBOARDING_step_2")} 3</h2>
              <div className='men-exc suc'><div>{Util.getMessage("ONBOARDING_step_Free_title")}</div></div>
              <h2 className="frame-4">{Util.getMessage("ONBOARDING_step_2_title")}</h2>
              <h2 className="frame-5">{Util.getMessage("ONBOARDING_step_2_sub_title_1")}</h2>
              
              <div className='radios'>
                <RadioHtml options={Util.DAYSORDERS} type="checkbox" value={shop.daysOrders} name="daysOrders" onChange={this.onChange} recomend={{value:"60", label:Util.getMessage("ONBOARDING_recomend")}}/>
              </div>
              <div className='men-exc'><div>{Util.getMessage("ONBOARDING_step_2_title_ext")}</div></div>
              <button disabled={!isAvailable} className="btn btn-dark" onClick={() => this.handleClick()}>{Util.getMessage("ONBOARDING_button_1")}</button>
            </div>
          )}
            
        </div>
      );
    }else{
      return (
      <div className="presentacion" >
        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>
    );
    }
  }
}
const mapStateToProps = (state) => ({
    user: state.user,
});
  
const mapDispatchToProps = dispatch => {
    return {
      dispatch
    }
}
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Presentation));
