import React, { useState, useEffect } from 'react';
import Campaigns from './Campaigns';
import * as Util from './../data/General';
import CreateFlow from './CreateFlow';


function Flow(props) {

    const [flow, setFlow] = useState(null);
    const [show, setShow] = useState(true);
    const [newSegment, setNewSegment] = useState(null);
    const [step, setStep] = useState(1);
    const [data, setData] = useState(null);
  
    const newSegmentCreate = (seg, data=null) => {
        console.log("data", data);
        setData(data);
        setNewSegment(seg);
        setFlow(null);
        setShow(false);
        setStep(2);
        
    }

    useEffect(() => {
        
      },[newSegment, step, show]);
    
    return (
        <div className=''>
            {flow ? (
                <CreateFlow configFlow={flow} cancel={() => setFlow(null)} setNewSegment={newSegmentCreate}/>
            ) : (
                <>
                    {show ? (<div className='content-flow content-reviews'>
                        <div className='title content-flex space-between space-top'>
                            {Util.getMessage("flow_title")} 
                        </div>
                        <label>{Util.getMessage("flow_text")}</label>
                        <div className='select-flow'>
                        {Util.FLOW_CAMPAIGNS && Object.keys(Util.FLOW_CAMPAIGNS).map((key, index) =>
                            <article key={index} onClick={() => setFlow(Util.FLOW_CAMPAIGNS[key])}>
                                <h2>{Util.getMessage(Util.FLOW_CAMPAIGNS[key].title)}</h2>
                                <p>{Util.getMessage(Util.FLOW_CAMPAIGNS[key].description)}</p>
                                <div className='content-flex space-between'>
                                    <img src='/img/ls.png' alt="Shopify" />
                                    <img src='/img/lw.png' alt="WhatsApp" />
                                </div>
                            </article>
                        )}
                        </div>
                    </div> ) : null }
                    {step === 1 ? (
                        <Campaigns type="AUTOMATIC" show={setShow} setOpenNew={() => setShow(!show)} dataExtra = {data} segment={newSegment}/>
                    ) : (
                        <Campaigns type="AUTOMATIC" show={setShow} setOpenNew={() => setShow(!show)} dataExtra = {data} segment={newSegment}/>
                    )}
                </>
            )}
        </div>
    );
}

export default Flow;
