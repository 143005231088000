import React, { useState, useEffect } from 'react';
import * as Util from './../../data/General';
import Button from '../html/Button';
import ChartRequest from './ChartRequest';
import DashboardCards from './DashboardCards';
import WhatsApp from '../html/WhatsApp';
import CurrencyFormat from 'react-currency-format';
import RememberProm from '../html/RememberProm';

export const ADDPOSITIVE = 3;
export const ADDNEGATIVE = -3;
export const MAX_WIDTH = 30;

function Campaign(props) {
  const [style, setStyle] = useState({
    width: 40,
    height: 40
    });

    const { product, isShowProduct, show, hide, handleOk, edit, remember, type, costMens } = props;
    let costMenssage = costMens * (product.campaignResult ? product.campaignResult.total : 0);

    const isOKRates = () => {
        let salesAmount = product.campaignResult && product.campaignResult.orderTotalPrice ? product.campaignResult.orderTotalPrice : 0;
        let shopify =  Util.getAdminShop();
        if (shopify && costMenssage > 0){
            let campaignResult = product.campaignResult && product.campaignResult.total ? product.campaignResult.total : null; 
            if(campaignResult){
                return (salesAmount/costMenssage).toFixed(2);
            }
        }
        return 0;
    }

  const [add, setadd] = useState(ADDPOSITIVE);
  const [count, setCount] = useState(0);
  const [isShow, setIsShow] = useState(false);
  const [data, setData] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [isSearchB, setIsSearchB] = useState(false);
  const [delivered, setDelivered] = useState(0);
  const [read, setRead] = useState(0);
  const [wa, setWa] = useState(null);
  const [idWa, setIdWa] = useState(0);
  const [listContSid, setListContSid] = useState(null);
  //const [sales, setSales] = useState(0);
  //const [rates, setRates] = useState(null);
  let roi = isOKRates();
  
  

  

  const showEfectCount = () => {
    setTimeout(function(){
        setStyle({
            width: style.width + add,
            height: style.height + add
        });
        setCount(count + 1);
    }.bind(this), 1);
    }

    const setHide = () => {
        setadd(ADDNEGATIVE);
        setCount(0);
    }
    const handleError = (data) => {
        console.log(data);
    }

    

    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };

    const getDateFormat = (dateString) =>{
        const event = new Date(dateString);
        return event.toLocaleDateString(window.paramRevie.language, options);
    }

    const getDateFormatHour = (dateString) =>{
        const event = new Date(dateString);
        return new Intl.DateTimeFormat(window.paramRevie.language, { dateStyle: 'full', timeStyle: 'medium' }).format(event);
    }

    const getDateFormatHour2 = (dateString) =>{
        const event = new Date(dateString);
        let options = {
            hour: "numeric",
            minute: "numeric",
            timeZone: "America/Mexico_City"
        };
        return new Intl.DateTimeFormat(window.paramRevie.language, options).format(event);
    }

    const isOK = (data) => {
        
        if(data && data.code === "200"){
            let contentSid = JSON.parse(data.data);
            setListContSid(contentSid);
            searcContenSid(contentSid);
        }
    }

    

    const searcContenSid = (contentSid) => {
        Object.keys(contentSid).map((item) => {
            if(contentSid[item].id === idWa){
                console.log("wa", item);
                setWa(contentSid[item]);
            }
        });
    }

    const isError = (error) => {

    }

/*const handleOkUTM = (data) =>{
    if(data && data.totalPrice && data.totalPrice > 0){
        //setSalesAmount(data.totalPrice);
        //setSales(data.mediumCount[0].count);
    }else{
        //Util.getCurrency(isOKRates);
    }
}*/
const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
const handleErrorUTM = (data) =>{
    
}
    
  useEffect(() => {
    if(isShowProduct && count < MAX_WIDTH){
        showEfectCount();
    }else if(isShowProduct && count >= MAX_WIDTH && add === ADDPOSITIVE){
        setIsShow(true);
        //Util.getStatisticsCampaigns(product.id, severOk, severError);
        if(!isSearch){
            setIsSearch(true);
            if(!window.intervalSet && "PENDING" === product.state){
                setupCountdown(".campaign-0", Date.now(), Date.parse(product.notifiedAt));
            }
            Util.getStatistics(dataOk, dataFail, `marketing/campaigns/${product.id}/statusstatistics`);
            //Util.getUtmStatistics(handleOkUTM, handleErrorUTM, `?utmSource=revie&utmMedium=whatsapp&utmCampaign=${product.campaignData.campaign}`);
            //Util.getCurrency(isOKRates);
            
        }
        if(wa === null && product.campaignData && product.campaignData.campaign){
            let idMysql = product.campaignData.campaign.split("_");
            idMysql = idMysql[(idMysql.length - 1)];
            setIdWa(idMysql);
            if(listContSid === null){
                Util.getContentSid("",isOK, isError);
            }else{
                searcContenSid(listContSid);
            }
        }
        
    }else if(isShowProduct && count >= MAX_WIDTH && add === ADDNEGATIVE){
        hide();
        setadd(ADDPOSITIVE);
        setCount(0);
        setIsShow(false);
        setIdWa(0);
        setData(null);
        setIsSearch(false);
        setWa(null);
        //setSalesAmount(0);
        //setSales(0);
        //setRoi(0);
        //setRates(null);
        setIsSearchB(false);
        //setCostMenssage(0);
        if(window.intervalSet){
            clearInterval(window.intervalSet);
            window.intervalSet = null;
        }
    }
    if(idWa !== 0 && wa === null && listContSid !== null){
        searcContenSid(listContSid);
    }
    /*if(rates === null){
        //Util.getCurrency(isOKRates);
    }*/
  },[count, isShowProduct, idWa, isShow, wa, listContSid, isSearchB]);

  const dataFail = (data) => {

  }

  const dataOk = (data) => {
    setData(data);

    let read = 0;
    let delivered = 0;
    if(data){
        data.map((item, key) =>{
            if(item.key === "read"){
                read += item.count; 
            } 
            if(item.key === "delivered" || item.key === "read" || item.key === "received" || item.key === "sent"){
                delivered += item.count; 
            }
        });
        setRead(read);
        setDelivered(delivered);
    }
  }

  const deleteCampaign = () => {

    setIsSearchB(true);
    Util.deleteCampaigns(product.id, isOkDeleteCampaign, isOkDeleteCampaign)
  }

  const isOkDeleteCampaign = (data) => {
    setHide();
    console.log("BORRADO O ACTUALIZADO");
    if(handleOk) handleOk();
    setIsSearchB(false);
  }

  const draftCampaign = () => {
    setIsSearchB(true);
    let prd = product;
    prd.state = "DRAFT";
    Util.updateCampaign(prd, isOkDeleteCampaign, isOkDeleteCampaign)
  }

  const newRemember = () =>{
    remember();
  }

  const isCreateRemeber = () =>{
    let now= Date.now();
    let max =  Date.parse(product.notifiedAt);
    max = max + (22*60*60*1000);
    if(now < max){
        return true;
    }

    return false;
  }

  const setupCountdown = (campaignSelector, startTimeMillis, endTimeMillis) => {
   var second = 1000;
   var minute = second * 60;
   var hour = minute * 60;
   var day = hour * 24;
  
   function calculateRemaining() {
    var now = new Date().getTime();
    return now >= startTimeMillis && now < endTimeMillis ? endTimeMillis - now : 0;
   }
  
   var previousGap = calculateRemaining();
  
   function countdown() {
    var gap = calculateRemaining();
    
    previousGap = gap;
  
    var textDay = Math.floor(gap / day);
    var textHour = Math.floor((gap % day) / hour);
    var textMinute = Math.floor((gap % hour) / minute);
    var textSecond = Math.floor((gap % minute) / second);
    console.log((textDay === 0 && textHour === 0 && textMinute === 0 && textSecond === 0));
    if(textDay === 0 && textHour === 0 && textMinute === 0 && textSecond === 0){
        if(window.intervalSet){
            clearInterval(window.intervalSet);
            window.intervalSet = null;
        }
        let hero = document.querySelector(campaignSelector);
        if(hero){
            hero.style.display = "none";
        }
    }else if (document.querySelector(campaignSelector + ' .timer')) {
     document.querySelector(campaignSelector + ' .day').innerText = textDay;
     document.querySelector(campaignSelector + ' .hour').innerText = textHour;
     document.querySelector(campaignSelector + ' .minute').innerText = textMinute;
     document.querySelector(campaignSelector + ' .second').innerText = textSecond;
    }
    
  
    
   }
  
   countdown();
   window.intervalSet = setInterval(function(){
    countdown();
  }, 1000);
  }

  const goToPedding = () => {
    props.dispatch({
        type: 'CHANGE_MENU',
        new_option: "/plan"
    });
}
    const retry = () => {
        setIsSearchB(true);
        let prd = product;
        prd.state = "PENDING";
        Util.updateCampaign(prd, isOkDeleteCampaign, isOkDeleteCampaign)
      }

  return (
    <>  
        {isShow && (
            <div className='link-ext-product'>
                <Button type="link" value={Util.getMessage("back_hide_campaign")} imageInc="img/back.png" onClick={() => setHide()}/>
            </div>
        )}
        <div onClick={() => {show(product)}} className={`content-review content-product-item ${product.state} ${isShowProduct ? 'show-unic' : ''}`} >
            <div className='content-img-prod'>
                    <div>
                        {product.name}
                    </div>
            </div>
            <div className='content-flex flex-center'>
                {type === "BASIC" ? (
                    <div className={`cont-date ${product.state}`}>{
                        ("PENDING" === product.state || "DRAFT" === product.state) ? 
                        (<div>{Util.getMessage("date_table_2")}: {getDateFormatHour(product.notifiedAt)}</div>)
                        : (<div>{Util.getMessage("date_table_3")}: {getDateFormatHour(product.notifiedAt)}</div>)}
                        <div>{getDateFormat(product.createdAt)}</div>
                    </div>
                ) : (
                    <div className={`cont-date ${product.state}`}>
                        <div><small>{Util.getMessage("confirm_campaign_2_2")} </small><b>{getDateFormatHour2(product.notifiedAt)}</b></div>
                    </div> 
                )}
                <div className='cont-stars cont-state'>{Util.getMessage(type === "BASIC" ? "camp_"+product.state : "camp_2_"+product.state)}</div>
                {!isShow ? (
                    <>
                        <div className="cont-total">
                            {product.campaignResult && 
                            product.campaignResult.total ? 
                            product.campaignResult.total : "-"}
                        </div>
                        <div className="cont-total">
                            {product.campaignResult && 
                            product.campaignResult.orderCount ? 
                            product.campaignResult.orderCount : "-"}
                        </div>
                        <div className="cont-total cont-total-esp">
                            {product.campaignResult && 
                            product.campaignResult.orderTotalPrice ? 
                            USDollar.format(product.campaignResult.orderTotalPrice) : "-"}
                        </div>
                        <div className="cont-total">
                            {roi}
                        </div>
                    </>
                    ) : null}
                {isShow && (
                    <div className='content-btn'>
                        {(product.state !== "PENDING") ? null : <Button disabled={isSearchB} loader={isSearchB} value={Util.getMessage("b_2")} onClick={() => draftCampaign()}/>}
                        {(product.state === "PENDING" || product.state === "DRAFT") ? (
                        <>
                            <Button loader={isSearchB} value={Util.getMessage("edit")} onClick={() => edit()}/> 
                            {product.state === "DRAFT" ? <Button loader={isSearchB} value={Util.getMessage("btn_retry")} onClick={() => retry()}/> : null} 
                        </> 
                        ) : null} 
                        {product.state === "PROCESSED" ? null : <Button disabled={isSearchB} loader={isSearchB} type="primary" value={Util.getMessage("b_1")} onClick={() => deleteCampaign()}/>}
                    </div>
                )}     
            </div>
        </div>
        {isShow && (
            <>
                <div style={{padding: "1rem"}} className={`campaigns ${(product && product.state) ? product.state : ''}`}>
                {(product.campaignResult && product.campaignResult.errorCode && product.state === "ERROR") ? (
                    <>
                        <div className='error-campaing'>
                            {Util.getMessage("error_"+product.campaignResult.errorCode)} 
                            <a href="#" onClick={() => goToPedding()}>{Util.getMessage("update_plan")}</a>
                            <Button loader={isSearchB} value={Util.getMessage("btn_retry")} onClick={() => retry()}/> 
                        </div>
                    </>
                ): null}

                    {product.campaignResult && product.campaignResult.total ? (
                        <div className="home">
                            <div className='content-flex space-between-start mobile-scroll flex-direction-column-mobile'>
                                <DashboardCards title={Util.getMessage("send_table_2")} count={product.campaignResult.total} titleTotal={Util.getMessage("message_text")} textTotal={""}/>
                                <DashboardCards title={Util.getMessage("campaign_delivereds")} count={delivered}  titleTotal={Util.getMessage("message_text")} percentage={((delivered/product.campaignResult.total)*100).toFixed(0)}  textTotal={""}/>
                                <DashboardCards title={Util.getMessage("campaign_reads")} count={read}  titleTotal={Util.getMessage("message_text")} percentage={delivered > 0 ?((read/delivered)*100).toFixed(0) : 0} textTotal={""}/>
                                {product.campaignResult ? (
                                    <>
                                    {/*(product.campaignResult.orderCount > 0)  ? (<DashboardCards title={Util.getMessage("message_text_sales")}  titleTotal={""} count={product.campaignResult.orderCount} percentage={0} textTotal={""}/>) : null*/}
                                    {(product.campaignResult.orderTotalPrice > 0)  ? (<DashboardCards title={Util.getMessage("campaign_sales")}  titleTotal={product.campaignResult.orderCount + " " + Util.getMessage("message_text_sales")} count={USDollar.format(product.campaignResult.orderTotalPrice)} percentage={roi} textTotal={""} addPercentage="x"/>) : null}
                                    </>
                                ) : null}

                            </div>
                        </div>
                    ) : null}
                    <div className='content-flex space-between-start data-campaing'>
                        {("PROCESSED" === product.state) ? (
                            <div className='analytics'>
                                {data && (
                                    <ChartRequest title={""} type="donut" isDataCheck={true} dataCheck={data} listColors={{"read":"#49A0FD","sent":"rgb(244, 165, 0)","read":"rgb(19, 153, 71)","undelivered":"rgb(253, 87, 84)", "queued": "rgb(119, 93, 208)", "failed": "rgb(255, 69, 96)", "delivered": "rgba(73, 160, 253, 0.85)", "error": "rgb(253, 87, 84)"}} typeValue="int2" pre_label="campaign_" typeLabel="traductor" name={"Reviews"} delay={300} url={`marketing/campaigns/${product.id}/statusstatistics`} />
                                )}
                            </div>
                        ) : null}
                        <div className='crate-wa-cont'>
                            <div className='crate-wa'>
                                <div>
                                    <div className='cont-wp'>
                                        {wa && (<WhatsApp message={wa} />)}
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
                <div className='datail-campaing'>
                    <label>{product.campaignData.segmentName ? Util.getMessage("confirm_campaign_3") : "CSV: "}<b>{product.campaignData.segmentName ? product.campaignData.segmentName : product.campaignData.audienceName}</b></label>
                    <label>{Util.getMessage("confirm_campaign_4")}<b>{product.campaignData.contentSid}</b></label>
                    { (costMenssage && costMenssage > 0) ? (<label>{Util.getMessage("confirm_campaign_6")}<b><CurrencyFormat value={costMenssage} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$'} /></b></label>) : null}
                    {"PENDING" === product.state ? (
                        <div id="hero" className="hero connect-page">
                            <div className="container">
                                <div className="hero-body">
                                <div className="campaign campaign-0">
                                    <div className="counter timer">
                                    <div className="counter-boxes">
                                        <div className="count-box">
                                        <h1 className="value day">0</h1>
                                        <span>{Util.getMessage("calendar_1")}</span>
                                        </div>
                                        <div className="count-box">
                                        <h1 className="value hour">0</h1>
                                        <span>{Util.getMessage("calendar_2")}</span>
                                        </div>
                                        <div className="count-box">
                                        <h1 className="value minute">0</h1>
                                        <span>{Util.getMessage("calendar_3")}</span>
                                        </div>
                                        <div className="count-box">
                                        <h1 className="value second">0</h1>
                                        <span>{Util.getMessage("calendar_4")}</span>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    ) : null}   
                    {product && product.campaignData  && product.campaignData.campaign && ("PROCESSED" === product.state || product.campaignType === "AUTOMATIC")? (
                    <>
                        <hr/>
                        <div className='text'>{Util.getMessage("link_marketing_text1")}</div>
                        <a href={`https://${Util.getShopDomain()}/admin/marketing/reports/tactic?attributionModel=any_click&utm_campaign_name=${product.campaignData.campaign}&utm_campaign_medium=whatsapp&referring_channel=revie`} target='_blank'>{Util.getMessage("link_marketing_link")} {product.campaignData.campaign}</a>
                    </>
                ) : null }
                    
                </div>
                {(product && product.campaignData && type === "BASIC" && ((("PROCESSED" === product.state) && isCreateRemeber()) || (("PENDING" === product.state)))  && !(product.parentId && product.parentId !== "")) ? (
                    <RememberProm costMens={("PENDING" === product.state) ? 0 :costMenssage} newOk={newRemember}/>
                ) : null }
                
            </>
        )}
    </>
);
}

export default Campaign;